import { Injectable } from '@angular/core';
import { Audience } from '../../../app/shared/utils/audience';

@Injectable({
  providedIn: 'root',
})
export class AudienceService {
  constructor() {}

  getAudienceForScope(serviceName: string): string {
    return Audience[serviceName as keyof typeof Audience].show;
  }
  
  getAudienceForToken(serviceName: string): string {
    return Audience[serviceName as keyof typeof Audience].manage;
  }
}
