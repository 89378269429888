import { from, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IrdetoAuthService } from './irdeto-auth.service';
import { AccessTokenService } from './access-token.service';
import { EnvService } from './env.service';
import { AudienceService } from './audience.service';

interface ServiceData {
  serviceId: string;
  tenantId: string;
  tenantName: string;
}

interface TenantList {
  [key: string]: string;
}

interface ScopeParams {
  scopes: string[];
  tenantId: string;
  audience: string;
  serviceId: string;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private scopes: { [key: string]: string[] } = {};
  private servicesLoaded = false;
  public currentServiceId: string | null = null;
  allServicesData: ServiceData[] = [];
  private serviceData: { [key: string]: ServiceData } = {};

  constructor(
    private auth: IrdetoAuthService,
    private tokenService: AccessTokenService,
    private audienceService: AudienceService,
    private envService: EnvService,
  ) {}

  fetchAllowedServices(): Observable<string[]> {
    if (this.servicesLoaded) {
      return of(Object.keys(this.serviceData));
    }

    return from(this.auth.requestServices()).pipe(
      take(1),
      tap((exercisableServices: ServiceData[]) => {
        this.allServicesData = exercisableServices;
        exercisableServices.forEach((el) => {
          this.serviceData[el.serviceId] = { ...el };
        });
        this.servicesLoaded = true;
      }),
      map(() => Object.keys(this.serviceData)),
    );
  }

  fetchTenants(): Observable<TenantList> {
    return of(this.serviceData).pipe(
      map((serviceData) => {
        const tenantList = Object.values(serviceData).reduce(
          (acc: TenantList, { tenantId, tenantName }) => {
            acc[tenantId] = tenantName;
            return acc;
          },
          {},
        );
        return tenantList;
      }),
    );
  }

  fetchScopesForService(
    serviceName: string,
    audience: string,
    tenantId?: string,
  ): Observable<string[]> {
    const serviceId = this.envService.getConfig()[serviceName];
    if (this.scopes[`${serviceId}:${audience}`]) {
      return of(this.scopes[`${serviceId}:${audience}`]);
    }
  
    return from(
      this.auth.requestScopes({
        tenant: tenantId ?? this.serviceData[serviceId]?.tenantId,
        audience,
        service: serviceId,
      }) as Promise<string[]>,  // Ensure it’s treated as a Promise<string[]>
    ).pipe(
      take(1),
      tap((scopes: string[]) => {
        this.scopes[`${serviceId}:${audience}`] = scopes;
      }),
    );
  }
  

  hasService(serviceId: string): boolean {
    return !!this.serviceData[serviceId];
  }

  getScopes(): string[] {
    return Object.keys(this.scopes);
  }

  hasScope(serviceName: string, scope: string, audience?: string): boolean {
    const serviceId = this.envService.getConfig()[serviceName];
    if (!audience) {
      audience = this.audienceService.getAudienceForScope(serviceName);
    }
    return this.scopes[`${serviceId}:${audience}`]?.includes(scope) ?? false;
  }

  setCurrentServiceId(currentServiceId: string): void {
    this.currentServiceId = currentServiceId;
  }

  getCurrentServiceId(): string | null {
    return this.currentServiceId;
  }

  setParamToGetCurrentServiceAccessToken(
    serviceName: string,
    audience: string,
    tenantId?: string,
  ): Observable<void> {
    const serviceId = this.envService.getConfig()[serviceName];
    if (!this.scopes[`${serviceId}:${audience}`]) {
      return this.fetchScopesForService(serviceName, audience).pipe(
        switchMap(() =>
          this.setParamToGetCurrentServiceAccessToken(serviceName, audience),
        ),
      );
    } else {
      const dataToBePassed: ScopeParams = {
        scopes: this.scopes[`${serviceId}:${audience}`],
        tenantId: tenantId ?? this.serviceData[serviceId]?.tenantId,
        audience,
        serviceId: serviceId,
      };
      this.tokenService.setParams(dataToBePassed);
      return of(undefined);
    }
  }

  setParamsForThirdPartyAccessToken(
    serviceName: string,
    audience: string,
  ): void {
    const serviceId = this.envService.getConfig()[serviceName];
    if (
      this.scopes[`${serviceId}:${audience}`] &&
      this.serviceData[serviceId]?.tenantId
    ) {
      const dataToBePassed: ScopeParams = {
        scopes: this.scopes[`${serviceId}:${audience}`],
        tenantId: this.serviceData[serviceId].tenantId,
        audience,
        serviceId,
      };
      this.tokenService.setParams(dataToBePassed);
    }
  }
}
